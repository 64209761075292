import React from "react";
import "./index.scss";
/* 
import Countdown from "react-countdown"; */
/* import useCountdown from "../../../../hooks/useCountdown";
import { useState } from "react"; */

function App() {
  /*   const [timer, setTimer] = useState(true); */
  /*   const [Ape] = useState(5000); */
  /*   const { days, min, sec } = useCountdown(
    new Date(Date.now() + 10000).toString()
  ); */
  return (
    <div className="apes-r">
      <div>
        {" "}
           {/* <span>We are Live</span> */}
        {/* <h3>We have sold out.</h3> */}
        {/* 
          <span>{Ape} / 20000</span> */}
      </div>
    </div>
  );
}
export default App;
